import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => {
      const userAgentInfo = navigator.userAgent;
      const Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (flag) {
        return import("@/views/HomeViewPC")
      } else {
        return import("@/views/HomeViewMobile")
      }
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
