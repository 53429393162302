<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
}
</script>

<style lang="less">
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background-image: url(/public/image/nami_2_20px.png);
  background-repeat: no-repeat;
  background-position: bottom;
}

::-webkit-scrollbar-button {
  background-color: lightcoral;
  border-radius: 0.5rem;
}

#app, html, body {
  width: 100%;
  margin: 0;
  background-color: #f5f5f5;
}
</style>
